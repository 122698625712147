<template>
  <div class="robot_set"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.5)"
  >
    <Header :id="2"></Header>
    <Ai100Header :has="1"></Ai100Header>
    <div class="details_box">
      <div class="main_container">
        <div class="sys_set">
          <div class="set_l">
            <div class="tit">
              <div>{{$t('lang.robotSet.title1')}}</div>
              <div class="sys_switch_con">
                <div class="sys_switch">{{$t('lang.robotSet.switch')}}</div>
                <div class="desc">
                  <el-switch
                    v-model="checked"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="switchChange">
                  </el-switch>
                </div>
              </div>
            </div>
            <el-row>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">API - id</div>
                  <div class="desc">{{api_key_1}}******{{api_key_2}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">API - key</div>
                  <div class="desc">{{api_secret_1}}******{{api_secret_2}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content" :style="{display: 'flex',justifyContent: 'space-between'}">
                  <el-button type="primary" class="change_api" @click="api_change()">{{$t('lang.robotSet.apiset')}}</el-button>
                  <el-dialog
                    :title="'API '+this.$t('lang.设置')"
                    :visible.sync="dialogFormVisible"
                    :close-on-click-modal= "false"
                    width="48%"
                  >
                  
                    <el-row type="flex" justify="space-between" class="api_change_style" v-if="has_api==0">
                      <el-col :span="5">
                        <div class="style_btn on" @click="api_change_click(1)">{{$t('lang.新增API')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div class="style_btn1">{{$t('lang.原API到期续期')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div class="style_btn1">{{$t('lang.平仓换新号API')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div class="style_btn1">{{$t('lang.直接换新号API')}}</div>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="space-between" class="api_change_style" v-if="has_api!=0">
                      <el-col :span="5">
                        <div class="style_btn1">{{$t('lang.新增API')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div :class="api_change_btn==2?'style_btn on':'style_btn'" @click="api_change_click(2)">{{$t('lang.原API到期续期')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div :class="api_change_btn==3?'style_btn on':'style_btn'" @click="api_change_click(3)">{{$t('lang.平仓换新号API')}}</div>
                      </el-col>
                      <el-col :span="5">
                        <div :class="api_change_btn==4?'style_btn on':'style_btn'" @click="api_change_click(4)">{{$t('lang.直接换新号API')}}</div>
                      </el-col>
                    </el-row>
                    <el-form :model="form">
                      <div class="zhu" v-show="api_change_btn==1">{{$t('lang.注：适用于新用户新增API操作。')}}</div>
                      <div class="zhu" v-show="api_change_btn==2">{{$t('lang.注：适用于原平台原账号API到期换新操作，保留全部数据继续执行。')}}</div>
                      <div class="zhu" v-show="api_change_btn==3">{{$t('lang.注：适用于更换平台或账号API，并平仓掉原账号持仓资产。')}}</div>
                      <div class="zhu" v-show="api_change_btn==4">{{$t('lang.注：忽略原有账号数据及资产，直接更换新平台新账号API操作。')}}</div>
                      <el-form-item :label="this.$t('lang.选择平台')">
                        <el-select v-model="form.coinId" :placeholder="this.$t('lang.选择平台')" @change="pt_change" :disabled="api_change_btn==2?true:false">
                          <el-option label="100SunEX·阳光壹佰" value="1"></el-option>
                          <el-option label="Huobi·火币" value="2"></el-option>
                          <el-option label="OKEX" value="3"></el-option>
                          <el-option label="Binance·币安" value="4"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="this.$t('lang.绑定UID')">
                        <el-input v-model="form.uid_num" autocomplete="off" placeholder="UID" :disabled="api_change_btn==2?true:false"></el-input>
                        <div class="tishi">{{ $t('lang.请输入正确的UID，否则无法获得交易返佣') }}</div>
                      </el-form-item>

                      <el-form-item :label="this.$t('lang.绑定API')">
                        <el-input v-model="form.access_key" autocomplete="off" placeholder="API-KEY"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                        <el-input v-model="form.secret_key" autocomplete="off" placeholder="SECRET-KEY"></el-input>
                      </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible  = false">{{$t('lang.取消')}}</el-button>
                      <el-button type="primary" @click="WithDrawSubmit()">{{$t('lang.确定')}}</el-button>
                    </span>
                  </el-dialog>
                  <div class="desc_herf desc_herf1" @click="open_api">{{$t('lang.robotSet.howset')}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.workpt')}}</div>
                  <span class="desc" v-if="title==1">100SunEX</span>
                  <span class="desc" v-if="title==2">Huobi 火币</span>
                  <span class="desc" v-if="title==3">OKEX</span>
                  <span class="desc" v-if="title==4">Binance·币安</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.ptuid')}}</div>
                  <div class="desc">{{platuid}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.mobile')}}</div>
                  <div class="desc">{{mobile.slice(0,3)}}****{{mobile.slice(7,11)}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.orderid')}}</div>
                  <div class="desc">{{order_id}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.stxj')}}</div>
                  <div :class="sysChecked=='API正常'?'desc green':'desc red'">{{sysChecked=='API正常'?this.$t('lang.robotSet.API正常'):$t('lang.robotSet.API失效')}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                  
                <div class="grid-content">
                  <div class="text">{{$t('lang.robotSet.stsx')}}</div>
                  <div class="grid-content" :style="{display: 'flex'}">
                    <div class="desc" v-if="yajin_time">{{yajin_time|dateformat("YYYY.MM.DD")}}</div>
                    
                    <div class="desc" v-else>{{$t('lang.暂无')}}</div>
                    <div class="desc_herf desc_herf1">{{$t('lang.robotSet.xufei')}}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <el-row type="flex" justify="space-between" class="set_btn set_btn1">
              
              <el-col :span="7">
                <el-button :class="btn_click==1?'active':''" @click="btnClick(1)">{{$t('lang.robotSet.btn1')}}</el-button>
              </el-col>
              <el-col :span="7">
                <el-button :class="btn_click==2?'active':''" @click="btnClick(2)">{{$t('lang.robotSet.btn2')}}</el-button>
              </el-col>
              <el-col :span="7">
                <el-button class="reset" icon="el-icon-refresh" @click="delOrder()">{{$t('lang.robotSet.btn3')}}</el-button>
              </el-col>
            </el-row>
            <el-checkbox-group  class="coin_checkbox" :min="1" :max="4" v-model="checkedCoins" @change="checkChange">
              <el-checkbox v-for="coin in coins" :label="coin.label" :key="coin.label" :disabled="coin.disabled" @change="checkChange1(coin)">{{coin.label}}</el-checkbox>
            </el-checkbox-group >
            <div class="set_con">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="item.title" :name="item.name" v-for="item in editableTabs" :key="item.text">
                  <el-row type="flex" justify="space-between">
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset1')}}</div>
                        <div class="desc">
                          <el-select  v-model="item.content.warehouse_management" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_fcgl"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset2')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.work_interval" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_gzqj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset3')}}</div>
                        <div class="desc">
                          <el-select 
                          v-model="item.content.buy_condition" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_mdtj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset4')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.stop_profit_sites" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_zytj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    
                  </el-row>
                  <el-row type="flex" justify="space-between">
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset5')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.stepintoair" placeholder="" :disabled="true">
                            <el-option
                              v-for="item in options_sdmj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset6')}}</div>
                        <div class="desc">
                          <el-input v-model="item.content.work_interval_price_b" placeholder="" :disabled="mdisabled" @change="Change"></el-input>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset7')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.buy_spacing" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_mdjj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset8')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.stop_profit_up" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_zyfs"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" justify="space-between">
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset12')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.warehouse_time" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_sxsj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset10')}}</div>
                        <div class="desc">
                          <el-input v-model="item.content.work_interval_price_a" placeholder="" :disabled="mdisabled" @change="Change"></el-input>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset11')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.buy_num" placeholder="" :disabled="mdisabled" @change="Change">
                            <el-option
                              v-for="item in options_mdbs"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="set_grid">
                        <div class="title">{{$t('lang.robotSet.coinset9')}}</div>
                        <div class="desc">
                          <el-select v-model="item.content.reset_time" placeholder="" :disabled="true">
                            <el-option
                              v-for="item in options_xhsj"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <div class="set_status set_status1">{{$t('lang.robotSet.coinset13')}}</div>
            </div>
            <el-row type="flex" justify="space-between" class="set_btn set_btn1" :style="{marginBottom:0}">
              
              <el-col :span="7">
                <el-button @click="backPage()">{{$t('lang.robotSet.btn4')}}</el-button>
              </el-col>
              <!-- <el-col :span="7">
                <el-button :disabled="btn_click==0||btn_click==1?true : false" @click="SysRecommendation()">系统推荐参数</el-button>
              </el-col> -->
              <el-col :span="7">
                <el-button :disabled="btn_click==0||btn_click==1?true : false" @click="LstTimeSetting()">{{$t('lang.robotSet.btn5')}}</el-button>
              </el-col>
              <el-col :span="7">
                <el-button  :class="checked==false?'active':''" :disabled="btn_click==0?true : false" @click="strategySetSub">{{$t('lang.robotSet.btn6')}}</el-button>
              </el-col>
            </el-row>
            
            <el-dialog
                title=""
                :visible.sync="dialogChi" 
                width="30%">
                <div class="title" :style="{display: 'flex',alignItems: 'center',marginBottom: '40px',fontSize: '16px'}">
                  
                  <div>您当前</div>
                  <div v-for="(item,index) in chicang" :key="index" :style="{marginRight: '10px'}">
                    {{item}}
                  </div>
                  <div>有持仓，请选择处理方式后进行更换！</div>
                </div>
              
              <div class="chicang_radio">
                <el-radio v-model="chicang_radio" label="1" border>市价自动平仓</el-radio>
                <el-radio v-model="chicang_radio" label="2" border>保留仓位卖单</el-radio>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogChi = false">{{$t('lang.取消')}}</el-button>
                <el-button type="primary"  @click="sure_chicang()">{{$t('lang.确定')}}</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="set_r">
            <div class="set_r_header">
              <div class="title">{{$t('lang.mycount.biaoti')}}</div>
              <el-button class="reset" @click="pingOrder()">{{$t('lang.mycount.pingcang')}}</el-button>
            </div>
            <el-row class="card_item">
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.zhuangtai')}}</div>
                  <div class="desc" v-if="checked" :style="{color:'#5bb50b'}">{{$t('lang.mycount.sysopen')}}</div>
                  <div class="desc" v-else :style="{color:'#f20000'}">{{$t('lang.mycount.sysclose')}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text2')}}</div>
                  <div class="desc" v-if="create_time">{{create_time|dateformat('YYYY.MM.DD')}}</div>
                  <div class="desc" v-else>{{$t('lang.暂无')}}</div>
                </div>
              </el-col>
              
              <el-col :span="8">
                <div class="item_flex">
                  <div :style="{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}">
                    <div class="text">{{$t('lang.mycount.text3')}}</div>
                    <div class="desc_herf" @click="open_time()">{{$t('lang.mycount.text3')}}</div>
                    <el-dialog
                      :title="this.$t('lang.mycount.text2')"
                      :visible.sync="dialogTime"
                      :close-on-click-modal= "false"
                      width="30%"
                    >
                      <div class="ct_div">
                        <el-date-picker
                          v-model="init_time"
                          type="date"
                          :placeholder="this.$t('lang.mycount.选择日期')"
                          @change="timeChange">
                        </el-date-picker>
                      </div>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogTime  = false">{{$t('lang.取消')}}</el-button>
                        <el-button type="primary" @click="dialogTimeSubmit()">{{$t('lang.确定')}}</el-button>
                      </div>
                    </el-dialog>
                  </div>
                  <div class="desc" v-if="sty==1&&checked" :style="{color: day>30?'#5bb50b':'rgba(16,16,16,.5)'}">{{day}} {{$t('lang.天')}}</div>
                  <div class="desc" v-else-if="sty==0&&checked">{{day}} {{$t('lang.小时')}}</div>
                  <div class="desc" v-else>{{$t('lang.暂无')}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="card_item">
              <el-col :span="8">
                <div class="item_flex">
                  <div :style="{display: 'flex',alignItems: 'center'}">
                    <div class="text">{{$t('lang.mycount.text4')}}</div>
                    <div class="desc_herf" @click="open_total()">{{$t('lang.设置')}}</div>
                    <el-dialog
                      :title="this.$t('lang.设置')"
                      :visible.sync="dialogTotal"
                      :close-on-click-modal= "false"
                      width="30%"
                    >
                      <el-input v-model="total_input" :placeholder="this.$t('lang.mycount.请输入正整数')" @input="inputChange"></el-input>
                      <div class="zhu_set">{{$t('lang.mycount.zhu') }}</div>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogTotal  = false">{{$t('lang.取消')}}</el-button>
                        <el-button type="primary" @click="dialogTotalSubmit()">{{$t('lang.确定')}}</el-button>
                      </div>
                    </el-dialog>
                  </div>
                  <div class="desc">{{Number(total).toFixed(2)}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text5')}}</div>
                  <div class="desc">{{Number(usdt_deposit).toFixed(2)}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div :style="{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}">
                    <div class="text">{{$t('lang.mycount.text6')}}</div>
                    <div class="desc_herf" @click="open()">{{$t('lang.设置')}}</div>
                    <el-dialog
                      :title="this.$t('lang.设置')"
                      :visible.sync="dialogMoney"
                      :close-on-click-modal= "false"
                      width="30%"
                    >
                      <div class="ct_div">
                        <el-radio v-model="radio" label="1">{{$t('lang.mycount.text5')}}</el-radio>
                        <el-radio v-model="radio" label="2">{{$t('lang.mycount.text6')}}</el-radio>
                      </div>
                      <el-input v-model="cz_money" :placeholder="$t('lang.mycount.text13')" v-if="radio==1" @input="oninput"></el-input>
                      <el-input v-model="tb_money" :placeholder="$t('lang.mycount.text14')" v-if="radio==2" @input="oninput"></el-input>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogMoney  = false">{{$t('lang.取消')}}</el-button>
                        <el-button type="primary" @click="dialogMoneySubmit()">{{$t('lang.确定')}}</el-button>
                      </div>
                    </el-dialog>
                  </div>
                  <div class="desc">{{Number(usdt_withdraw).toFixed(2)}}</div>
                </div>
              </el-col>
            </el-row>
            
            <el-row class="card_item">
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text7')}}</div>
                  <div class="desc">{{Number(usdtNum).toFixed(2)}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text8')}}</div>
                  <div class="desc">{{Number(inventory).toFixed(2)}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text9')}}</div>
                  <div class="desc">{{Number(now_total).toFixed(2)}}</div>
                </div>
              </el-col>
            </el-row>
            
            <el-row class="card_item">
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text10')}}</div>
                  <!-- <div class="desc">{{now_profit}}</div> -->
                  <div class="desc" :style="{color: now_profit >= 0? '#5bb50b':'#f20000'}">
                    <span v-if="now_profit>0">+</span>
                    <span>{{Number(now_profit).toFixed(2)}}</span>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text11')}}</div>
                  <div class="desc" :style="{color: profitRateNow > 0? '#5bb50b':'#f20000'}" v-if="profitRateNow>0">
                    <span>+</span>
                    <span>{{Number(profitRateNow).toFixed(2)}}%</span>
                  </div>
                  <div class="desc" :style="{color: '#5bb50b'}" v-else>
                    <span>0.00%</span>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="item_flex">
                  <div class="text">{{$t('lang.mycount.text12')}}</div>
                  <div class="desc" :style="{color: profitRate > 0? '#5bb50b':'#f20000'}" v-if="profitRate>0">
                    <span>+</span>
                    <span>{{Number(profitRate).toFixed(2)}}%</span>
                  </div>
                  <div class="desc" :style="{color: '#5bb50b'}" v-else>
                    <span>0.00%</span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="set_con" v-loading="loading1" element-loading-background="rgba(0, 0, 0, 0.6)">
              <el-tabs v-model="activeName1" @tab-click="handleClick1">
                <el-tab-pane :label="this.$t('lang.mycount.ccqk')" name="1">
                  <el-row type="flex" class="tit_table" justify="space-between">
                    <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                    <el-col :span="7"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                    <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.ccjj')}}</div></el-col>
                    <el-col :span="7"><div class="tit_item">{{$t('lang.mycount.total')}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(btcnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">BTC</div></el-col>
                    <el-col :span="7"><div class="item_num">{{btcnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{btcnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(btcnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(ethnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">ETH</div></el-col>
                    <el-col :span="7"><div class="item_num">{{ethnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{ethnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(ethnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(dotnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">DOT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{dotnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{dotnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(dotnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(htnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">HT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{htnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{htnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(htnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(bchnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">BCH</div></el-col>
                    <el-col :span="7"><div class="item_num">{{bchnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{bchnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(bchnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(ltcnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">LTC</div></el-col>
                    <el-col :span="7"><div class="item_num">{{ltcnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{ltcnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(ltcnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <!-- <el-row type="flex" :class="Number(suntnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">SUNT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{suntnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{suntnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(suntnum_all).toFixed(4)}}</div></el-col>
                  </el-row> -->
                  <el-row type="flex" :class="Number(solnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">SOL</div></el-col>
                    <el-col :span="7"><div class="item_num">{{solnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{solnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(solnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(linknum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">LINK</div></el-col>
                    <el-col :span="7"><div class="item_num">{{linknum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{linknum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(linknum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(bnbnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">BNB</div></el-col>
                    <el-col :span="7"><div class="item_num">{{bnbnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{bnbnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(bnbnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                  <el-row type="flex" :class="Number(okbnum_all)>5?'body_item on':'body_item'" justify="space-between">
                    <el-col :span="5"><div class="item_num">OKB</div></el-col>
                    <el-col :span="7"><div class="item_num">{{okbnum}}</div></el-col>
                    <el-col :span="5"><div class="item_num">{{okbnum_pve}}USDT</div></el-col>
                    <el-col :span="7"><div class="item_num">{{Number(okbnum_all).toFixed(4)}}</div></el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane :label="this.$t('lang.mycount.gzzt')" name="2">
                  <el-row type="flex" class="tit_table" justify="space-between">
                    <el-col :span="6"><div class="tit_item">{{$t('lang.mycount.time')}}</div></el-col>
                    <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                    <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.direction')}}</div></el-col>
                    <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.price')}}</div></el-col>
                    <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                  </el-row>
                  <div class="history_data">
                    <el-row type="flex" class="body_item" justify="space-between" v-for="(item,index) in buy_order" :key="'buy'+index">
                      <el-col :span="6">
                        <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                        <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                      </el-col>
                      <el-col :span="4"><div class="item_num">{{item.symbol}}</div></el-col>
                      <el-col :span="4">
                        <div class="item_num" :style="{color:'#5bb50b'}">{{$t('lang.mycount.buy')}}</div>
                      </el-col>
                      <el-col :span="5"><div class="item_num">{{item.price}}</div></el-col>
                      <el-col :span="5"><div class="item_num">{{item.num}}</div></el-col>
                    </el-row>
                    <el-row type="flex" class="body_item" justify="space-between" v-for="(item,index) in sell_order" :key="'sell'+index">
                      <el-col :span="6">
                        <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                        <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                      </el-col>
                      <el-col :span="4"><div class="item_num">{{item.symbol}}</div></el-col>
                      <el-col :span="4">
                        <div class="item_num" :style="{color:'#f20000'}">{{$t('lang.mycount.sell')}}</div>
                      </el-col>
                      <el-col :span="5"><div class="item_num">{{item.price}}</div></el-col>
                      <el-col :span="5"><div class="item_num">{{item.num}}</div></el-col>
                    </el-row>
                    <div class="no_data" v-if="noData&&this.loading1==false">{{$t('lang.暂无数据')}}</div>
                  </div>
                </el-tab-pane>
                <el-tab-pane :label="this.$t('lang.mycount.lscj')" name="3">
                  <el-row type="flex" class="tit_table" justify="space-between">
                    <el-col :span="6"><div class="tit_item">{{$t('lang.mycount.time')}}</div></el-col>
                    <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.coinname')}}</div></el-col>
                    <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.direction')}}</div></el-col>
                    <el-col :span="4"><div class="tit_item">{{$t('lang.mycount.price')}}</div></el-col>
                    <el-col :span="3"><div class="tit_item">{{$t('lang.mycount.num')}}</div></el-col>
                    <el-col :span="5"><div class="tit_item">{{$t('lang.mycount.money')}}</div></el-col>
                  </el-row>
                  <div class="history_data">
                    <div class="no_data" v-if="noData1&&this.loading1==false">{{$t('lang.暂无数据')}}</div>
                    <el-row  class="body_item" v-for="(item,index) in history_list" :key="index">
                      <el-col :span="6">
                        <div class="item_num">{{item.create_time|dateformat("YYYY.MM.DD")}}</div>
                        <div class="item_num">{{item.create_time|dateformat("HH:mm:ss")}}</div>
                      </el-col>
                      <el-col :span="3"><div class="item_num">{{item.symbol}}</div></el-col>
                      <el-col :span="3">
                        <div class="item_num" :style="{color:'#f20000'}" v-if="item.order_type==2">{{$t('lang.mycount.sell')}}</div>
                        <div class="item_num" :style="{color:'#5bb50b'}" v-if="item.order_type==1">{{$t('lang.mycount.buy')}}</div>
                      </el-col>
                      <el-col :span="4"><div class="item_num">{{Number(item.price).toFixed(2)}}</div></el-col>
                      <el-col :span="3"><div class="item_num">{{Number(item.num).toFixed(4)}}</div></el-col>
                      <el-col :span="5"><div class="item_num">{{Number(item.amount).toFixed(4)}}</div></el-col>
                    </el-row>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div class="set_status" @click="refresh_click">{{$t('lang.刷新')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
var dot=0;
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Ai100Header from '../../components/Ai100Header/Index'
import { IsNullOrBe,userInfo,addapiGet,addapi,paramefollow,parmeworking,paramesave,systemSetting,paramerecover,marketSell,closeout,tacticsmessage,userinventory,depositwithdraw,changecreatetime,changeTotal, loginOrderId} from '../../interface.js'
export default {
  name: 'RobotSetDetails',
  components: {
    Header,
    Ai100Header,
    Footer
  },
  data() {
    return {
      checkedCoins: [],
      disabled: true,
      coins: [
        {
          label: 'BTC/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'ETH/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'DOT/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'LTC/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'BCH/USDT',
          flag: false,
          disabled: false,
        },
        // {
        //   label: 'SUNT/USDT',
        //   flag: false,
        //   disabled: false,
        // },
        {
          label: 'SOL/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'HT/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'OKB/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'LINK/USDT',
          flag: false,
          disabled: false,
        },
        {
          label: 'BNB/USDT',
          flag: false,
          disabled: false,
        }
      ],
      loading: true,
      checked: false,
      activeName: '1',
      editableTabs: [],
      activeName1: '1',
      options_fcgl: [
        { value: 1, label: '18'},
        { value: 2, label: '20'},
        { value: 3, label: '22'},
        { value: 4, label: '24'},
        { value: 5, label: '26'},
        { value: 6, label: '28'},
        { value: 7, label: '30'},
        { value: 8, label: '32'},
        { value: 9, label: '34'},
        { value: 10, label: '36'},
        { value: 11, label: '38'},
        { value: 12, label: '40'},
      ],
      options_sdmj: [
        { value: 0, label: '开启'},
        { value: 1, label: '关闭'},
      ],
      options_xhsj: [
        { value: 1, label: '60'},
      ],
      options_mdtj: [
        { value: 1, label: '0.5'},
        { value: 2, label: '0.6'},
        { value: 3, label: '0.7'},
        { value: 4, label: '0.8'},
        { value: 5, label: '0.9'},
        { value: 6, label: '1.0'},
        { value: 7, label: '1.1'},
        { value: 8, label: '1.2'},
        { value: 9, label: '1.3'},
        { value: 10, label: '1.4'},
        { value: 11, label: '1.5'},
        { value: 12, label: '2.0'},
        { value: 13, label: '2.5'},
        { value: 14, label: '3.0'},
        { value: 15, label: '3.5'},
        { value: 16, label: '4.0'},
        { value: 17, label: '4.5'},
        { value: 18, label: '5.0'},
      ],
      options_mdjj: [
        { value: 1, label: '1.0'},
        { value: 2, label: '1.2'},
        { value: 3, label: '1.4'},
        { value: 4, label: '1.6'},
        { value: 5, label: '1.8'},
        { value: 6, label: '2.0'},
        { value: 7, label: '2.5'},
        { value: 8, label: '3.0'},
        { value: 9, label: '3.5'},
        { value: 10, label: '4.0'},
        { value: 11, label: '4.5'},
        { value: 12, label: '5.0'},
      ],
      options_mdbs: [
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
      ],
      options_gzqj: [//工作区间
        {
          value: 0,
          label: this.$t('lang.robotSet.系统默认')
        },
        {
          value: 1,
          label: this.$t('lang.robotSet.手动设置')
        }
      ],
      options_zytj: [
        { value: 1, label: '1.0'},
        { value: 2, label: '1.5'},
        { value: 3, label: '2.0'},
        { value: 4, label: '2.5'},
        { value: 5, label: '3.0'},
        { value: 6, label: '3.5'},
        { value: 7, label: '4.0'},
        { value: 8, label: '4.5'},
        { value: 9, label: '5.0'},
      ],
      options_zyfs: [
        { value: 0, label: this.$t('lang.robotSet.单笔止盈')},
        { value: 1, label: this.$t('lang.robotSet.多笔止盈')},
      ],
      options_sxsj: [
        { value: 1, label: '30'},
      ],
      btn_click: 0,
      platuid: '',
      api_key_1: '*****',
      api_key_2: '*****',
      api_secret_1: '*****',
      api_secret_2: '*****',
      total: '0.0000',//初始金额
      create_time: '',//初始时间
      inventory: '0.0000',//持仓总成本
      profitRate: "0.00",//综合年化
      profitRateNow: '0.00',//当前收益
      usdtNum: "0.0000",//usdt价格/当前金额
      now_total: '0.0000',//当前总值
      yajin_time: '',//策略时效
      btcnum: "0.0000",
      btcnum_pve: "0.00",
      btcnum_all: "0.0000",
      ethnum: "0.0000",
      ethnum_pve: "0.00",
      ethnum_all: "0.0000",
      dotnum: "0.0000",
      dotnum_pve: "0.00",
      dotnum_all: "0.0000",
      htnum: "0.0000",
      htnum_pve: "0.00",
      htnum_all: "0.0000",
      bchnum: "0.0000",
      bchnum_pve: "0.00",
      bchnum_all: "0.0000",
      ltcnum: "0.0000",
      ltcnum_pve: "0.00",
      ltcnum_all: "0.0000",
      suntnum: "0.0000",
      suntnum_pve: "0.00",
      suntnum_all: "0.0000",
      solnum: "0.0000",
      solnum_pve: "0.00",
      solnum_all: "0.0000",
      linknum: "0.0000",
      linknum_pve: "0.00",
      linknum_all: "0.0000",
      bnbnum: "0.0000",
      bnbnum_pve: "0.00",
      bnbnum_all: "0.0000",
      okbnum: "0.0000",
      okbnum_pve: "0.00",
      okbnum_all: "0.0000",
      dialogFormVisible: false,
      form: {
        uid_num: '',
        coinId: '1',
        coinSty: '100SunEX·阳光壹佰',
        access_key: '',
        secret_key: ''
      },
      // formLabelWidth: '110px',
      mdisabled: true,
      // work_status: [],
      buy_order: [],
      sell_order: [],
      history_list: [],
      noData: false,
      noData1: false,
      mobile: '',
      loading1: false,
      now_btn: 0,
      activeLabel: 'ETH/USDT',
      sysChecked: '',
      workinterval: '',
      has_api: 0,
      title: '',
      api_change_btn: 1,
      chicang: [],
      dialogChi: false,
      chicang_radio: "1",
      chicang_data: [],
      sys_list: [],
      order_id: '',
      dialogMoney: false,
      cz_money: '',
      tb_money: '',
      radio: '1',
      usdt_deposit: '',
      usdt_withdraw: '',
      init_time: '',
      dialogTime: false,
      dialogTotal: false,
      total_input: '',
      init_time1: '',
      now_profit: '0.00',
      day: '0',
      sty: ''
    }
  },
  methods: {
    tacticsmessage () {
      tacticsmessage(this.mobile,res=>{
        // console.log(res)
        if(res.code==200){
          this.sys_list = res.data;
          var index = this.$route.query.id;
          // console.log(index)
          // console.log(res.data[index].order_id)
          this.order_id = res.data[index].order_id;
          this.yajin_time = res.data[index].end_time;
          this.addapiGet();
          this.getUserInfo();
          this.paramefollow();
          this.userinventory();
        }else{
          this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
          })
          this.loading = false;
        }
      })
    },
    pingOrder () {//一键快速平仓
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        this.$confirm(this.$t('lang.确认平仓吗？'), this.$t('lang.确认提示'), {
          confirmButtonText: this.$t('lang.确定'),
          cancelButtonText: this.$t('lang.取消'),
          type: 'warning'
        }).then(() => {
          this.loading = true;
          closeout(this.order_id,res=>{
            if(res.code==200){
              this.$alert(this.$t('lang.平仓成功'), this.$t('lang.提示'), {
                confirmButtonText: this.$t('lang.确定'),
              })
              this.loading = false;
              this.getUserInfo();
              this.userinventory();
            }else{
              this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
                confirmButtonText: this.$t('lang.确定'),
              })
              this.loading = false;
            }
          })
        }).catch(()=>{})
      }
    },
    addapiGet () {//系统巡检
      addapiGet(this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
          this.sysChecked = res.polling;
          this.title = res.data.title;
          this.platuid = res.data.platuid?res.data.platuid:0;
          this.checked = res.data.is_switch==1?true:false;
          this.total = res.data.total;
          this.create_time = res.data.create_time;
          var now_date = new Date().getTime()/1000-res.data.create_time;
          if(now_date>0 && now_date<86400){
            this.day = Math.floor(now_date/60/60);
            this.sty = 0;
          }else{
            this.day = Math.floor(now_date/24/60/60);
            this.sty = 1;
          }
          this.has_api = res.data.api_key?1:0;
          this.api_change_btn = res.data.api_key?2:1;
          if(res.data.title=='1'||res.data.title=='4'){
              this.api_key_1 = res.data.api_key?res.data.api_key.slice(0,6):0;
              this.api_key_2 = res.data.api_key?res.data.api_key.slice(-6):0;
              this.api_secret_1 = res.data.api_value?res.data.api_value.slice(0,6):0;
              this.api_secret_2 = res.data.api_value?res.data.api_value.slice(-6):0;
            }else{
              this.api_key_1 = res.data.api_key?res.data.api_key.split('-')[0].slice(0,6):0;
              this.api_key_2 = res.data.api_key?res.data.api_key.split('-')[3].slice(-6):0;
              this.api_secret_1 =  res.data.api_value?res.data.api_value.split('-')[0].slice(0,6):0;
              this.api_secret_2 =  res.data.api_value?res.data.api_value.split('-')[3]:0;
            }
            
        }
      })
    },
    look_jiayi () {//查看交易情况
      this.$alert(this.$t('lang.请自行登录账号所在平台前端查看'), this.$t('lang.提示'), {
        confirmButtonText: this.$t('lang.确定'),
      })
    },
    refresh_click () {//刷新
      this.loading1 = true;
      this.getUserInfo();
    },
    backPage () {
      this.$router.push('/Ai100');
    },
    SysRecommendation () {//系统推荐参数
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        this.parmeworking ()
      }
    },
    LstTimeSetting () {//恢复上次设置
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        if(this.now_btn==0){
          this.$alert(this.$t('lang.暂无可恢复的设置，请添加参数并保存参数设置'), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
          })
        }else{
          paramerecover(this.order_id,res=>{
            // console.log(res)
            if(res.code==200){
              this.$alert(this.$t('lang.恢复上次设置操作成功'), this.$t('lang.提示'), {
                confirmButtonText: this.$t('lang.确定'),
              })
              var coin_data = res.data;
              this.checkedCoins = [];
              this.editableTabs = []
              coin_data.map((item, index)=>{
                // console.log(item)
                this.checkedCoins.push(item.currency_type.toUpperCase())
                // for(let i in item){
                item.stepintoair = Number(item.stepintoair)
                // }
                this.editableTabs.push({
                  title: item.currency_type.toUpperCase(),
                  name: Number(index+1).toString(),
                  content: item
                })
              })
            }else{
              this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
                confirmButtonText: this.$t('lang.确定'),
              })
            }
          })
        }
      }
    },
    open_api () {//如何获取API
      // this.$router.push('/OpenApi');
    },
    delOrder () {//策略重置
      this.$alert(this.$t('lang.暂未开放'), this.$t('lang.提示'), {
        confirmButtonText: this.$t('lang.确定'),
      })
    },
    StrategyStatus () {
      // this.$router.push('/StrategyStatus');
    },
    pt_change (val) {//平台切换
      this.form.coinId = val;
      if(val==3){
        this.$message(this.$t('lang.暂未开放'));
        this.form.coinId = '1';
        this.form.coinSty = "100SunEX·阳光壹佰"
      }
    },
    switchChange (status) {//系统开关
      // console.log(status)
      this.$confirm(this.$t('lang.确定')+(status==true?this.$t('lang.开启'):this.$t('lang.关闭'))+'？', this.$t('lang.确认提示'), {
        confirmButtonText: this.$t('lang.确定'),
        cancelButtonText: this.$t('lang.取消'),
        type: 'warning'
      }).then(() => {
        var iswitch = status==true?1:2
        this.loading = true;
        systemSetting(this.order_id,iswitch,res=>{
          if(res.code==200){
            this.checked = status;
            this.getUserInfo();
            this.userinventory();
            this.paramefollow();
            this.addapiGet();
            this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
              confirmButtonText: this.$t('lang.确定'),
            })
          }else{
            this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
              confirmButtonText: this.$t('lang.确定'),
            })
            this.loading = false;
            this.checked = !status;
          }
        })
      }).catch(() => {
        this.loading = false;
        this.checked = !status;
      });
    },
    Change () {//select 切换
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }
    },
    api_change () {//更换api
      if(this.checked==false){
        this.form.coinId = this.title;
        this.form.uid_num = this.platuid;
        this.dialogFormVisible = true;
      }else{
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }
    },
    api_change_click (num) {//API更换方式切换
      this.api_change_btn = num;
    },
    WithDrawSubmit () {//更换API提交
      const uid_num = this.form.uid_num;
      const access_key = this.form.access_key;
      const secret_key = this.form.secret_key;
      const pt =this.form.coinId;
      // console.log(pt)
      var numReg = /^[0-9]*$/
      var numRe = new RegExp(numReg)
      if(this.api_change_btn!=2){
        if(!uid_num){
          this.$message(this.$t('lang.请输入账号的UID'));
          return false;
        }
        if (!numRe.test(uid_num)) {
          this.$message(this.$t('lang.UID无效'));
          return false
        }
        if(!access_key){
          this.$message(this.$t('lang.请输入Access Key'));
          return false;
        }
        if(!secret_key){
          this.$message(this.$t('lang.请输入Secret Key'));
          return false;
        }
      }else{
        if(!access_key){
          this.$message(this.$t('lang.请输入Access Key'));
          return false;
        }
        if(!secret_key){
          this.$message(this.$t('lang.请输入Secret Key'));
          return false;
        }
      }
      this.$confirm(this.$t('lang.确定绑定吗？'), this.$t('lang.确认提示'), {
        confirmButtonText: this.$t('lang.确定'),
        cancelButtonText: this.$t('lang.取消'),
      }).then(() => {
          addapi(this.order_id,pt,uid_num,access_key,secret_key,this.api_change_btn,res=>{
            // console.log(res)
            if(res.code==200){
              this.getUserInfo ();
              this.userinventory();
              this.paramefollow ();
              this.addapiGet();
              this.$message({
                message: this.has_api==0?this.$t('lang.API添加成功'):this.$t('lang.API更换成功'),
                type: 'success'
              });
              this.dialogFormVisible = false;
            }else{
              this.$message({
                message: this.$t('lang.' + res.msg),
                type: 'error'
              });
            }
          })
        }).catch(() => { 
          console.log('取消了')
        });
      
    },
    checkChange1 (val) {
      // alert(this.now_btn)
      // if(this.now_btn==2){
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        // alert(1)
        
        val.flag = !val.flag
        if(val.flag){
          var name_arr = []
          this.editableTabs.map(item=>{
            name_arr.push(item.name)
          })
          name_arr.sort(function(x, y){
              return y.name - x.name;
          });
          var name_new = name_arr[name_arr.length-1];
          this.editableTabs.push({
            title: val.label,
            name: Number(Number(name_new)+1).toString(),
            text: val.label.toLowerCase().replace("/usdt","usdt"),
            content: {
              buy_condition: "1.2",
              buy_num: "3",
              buy_spacing: "2.0",
              isLimit: 0,
              reset_time: "60",
              stepintoair: 1,
              stop_profit_sites: "2.0",
              stop_profit_up: 0,
              symbol: "1",
              warehouse_management: "38",
              warehouse_time: "30",
              work_interval: 0,
              work_interval_price_a: "",
              work_interval_price_b: "",
            }
          });
        }else{
          
          var editableTabs = this.editableTabs;
          editableTabs.map((item,index)=>{
            if(item.title==val.label){
              this.editableTabs.splice(index,1);
            }
          })
        }
        // if(this.checkedCoins.indexOf(val.label)==-1){
        //   this.checkedCoins.push(val.label);
        // }
      }
    },
    checkChange (val) {
      // console.log(val)
      this.checkedCoins = val;
      this.activeName=this.editableTabs[0].name;
    },
    handleClick(event) {
      this.activeName = event.name;
      this.activeLabel = event.label;
    },
    handleClick1(event) {
      this.activeName1 = event.name;
    },
    btnClick (num) {
      if(this.checked==true){
        this.coins.map(item=>{
          item.disabled = true;
        })
        this.btn_click = this.now_btn;
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        this.btn_click = num;
        if(num==1){
          this.coins.map(item=>{
            item.disabled = true;
          })
          this.mdisabled = true;
          this.loading = true;
          this.parmeworking();
        }else{
          this.mdisabled = false;
          if(this.title=='2'){
            this.coins.map(item=>{
              if(item.label=='SUNT/USDT'||item.label=='OKB/USDT'||item.label=='BNB/USDT'){
                item.disabled = true;
              }else{
                if(this.checked==true){
                  item.disabled = true;
                }else{
                  item.disabled = false;
                }
              }
            })
          }
          else if(this.title=='4'){
            this.coins.map(item=>{
              if(item.label=='HT/USDT'||item.label=='OKB/USDT'||item.label=='BNB/USDT'){
                item.disabled = true;
              }else{
                if(this.checked==true){
                  item.disabled = true;
                }else{
                  item.disabled = false;
                }
              }
            })
          }
          else if(this.title=='1'){
            this.coins.map((item)=>{
              if(this.checked==true){
                item.disabled = true;
              }else{
                item.disabled = false;
              }
            })
          }
        }
      }
      
    },
    parmeworking () {//系统推荐参数
      parmeworking (this.title,res =>{
        if(res.code==200&&res.coin){
          this.coins.map(item=>{
            item.flag = false;
          })
          this.loading = false;
          var coin_data = res.coin;
          this.checkedCoins = [];
          this.editableTabs = [];
          var count = 0;
          for(let i in coin_data){
            if(coin_data[i].symbol=='1'){
              this.checkedCoins.push(i.toUpperCase().replace("USDT","/USDT"))
              coin_data[i].stepintoair = Number(coin_data[i].stepintoair)
              this.editableTabs.push({
                title: i.toUpperCase().replace("USDT","/USDT"),
                name: Number(count).toString(),
                text: i,
                content: coin_data[i]
              })
              this.coins.map(item=>{
                
                if(item.label==i.toUpperCase().replace("USDT","/USDT")){
                  item.flag = true;
                }
                
              })
            }
            count++;
            
            
            // console.log(this.coins)
          }
          
        }else{
          this.loading = false;
        }
        
      })
    },
    errorMsg (res) {
      this.$message(res.msg);
      this.loading = false;
    },
    paramefollow () {//正在执行的参数
      paramefollow(this.order_id,res=>{
        if(res.code==200){
          this.btn_click = res.workinterval;
          this.now_btn = res.workinterval;
          var coin_data = res.data;
          this.checkedCoins = [];
          this.editableTabs = [];
          this.coins.map(item=>{
            item.flag = false;
          })
          coin_data.map((item, index)=>{
            this.checkedCoins.push(item.currency_type.toUpperCase())
            // for(let i in item){
            item.stepintoair = Number(item.stepintoair)
            // }
            this.editableTabs.push({
              title: item.currency_type.toUpperCase(),
              name: Number(index+1).toString(),
              text: item.symbol,
              content: item
            })
            this.coins.map(i=>{
              if(i.label==item.currency_type.toUpperCase()){
                i.flag = true;
              }
              
            })
          })
          if(this.btn_click==1){
            this.coins.map(item=>{
              item.disabled = true;
            })
            this.mdisabled = true;
          }
          
            // console.log(this.coins)
          if(this.btn_click==2){
            if(this.checked==true){
              this.coins.map(item=>{
                item.disabled = true;
              })
              this.mdisabled = true;
            }else{
              this.mdisabled = false;
              if(this.title=='2'){
                this.coins.map(item=>{
                  if(item.label=='SUNT/USDT'||item.label=='OKB/USDT'||item.label=='BNB/USDT'){
                    item.disabled = true;
                  }else{
                    if(this.checked==true){
                      item.disabled = true;
                    }else{
                      item.disabled = false;
                    }
                  }
                })
              }
              else if(this.title=='4'){
                this.coins.map(item=>{
                  if(item.label=='HT/USDT'||item.label=='OKB/USDT'||item.label=='BNB/USDT'){
                    item.disabled = true;
                  }else{
                    if(this.checked==true){
                      item.disabled = true;
                    }else{
                      item.disabled = false;
                    }
                  }
                })
              }
              else if(this.title=='1'){
                this.coins.map((item)=>{
                  if(this.checked==true){
                    item.disabled = true;
                  }else{
                    item.disabled = false;
                  }
                })
              }
            }
          }
        }else{
          this.btn_click = 1;
          this.coins.map(item=>{
            item.disabled = true;
          })
          this.mdisabled = true;
          this.parmeworking();//系统推荐参数
          this.errorMsg(res)
        }
      })
    },
    strategySetSub () {//保存参数
      if(this.checked==true){
        this.$alert(this.$t('lang.策略正在运行，请关闭后再操作'), this.$t('lang.提示'), {
          confirmButtonText: this.$t('lang.确定'),
        })
        return false;
      }else{
        var params_sub = this.editableTabs;
          
        //  console.log(params_sub)
        var itemArr = []
        params_sub.map(item=>{
          for(let i in item.content){
            if(item.content[i]===''||item.content[i]===null||item.content[i]===undefined){
              itemArr.push(item)
            }else{
              this.$confirm(this.$t('lang.确认保存参数设置吗？'), this.$t('lang.确认提示'), {
                confirmButtonText: this.$t('lang.确定'),
                cancelButtonText: this.$t('lang.取消'),
              }).then(() => {
                this.loading = true;
                paramesave (this.order_id,this.btn_click,params_sub,res=>{
                  // console.log(res)
                  if(res.code==200){
                    this.getUserInfo();
                    this.paramefollow();
                    this.userinventory();
                    this.addapiGet();
                    // this.loading = false;
                    this.$alert(this.$t('lang.保存成功'), this.$t('lang.提示'), {
                      confirmButtonText: this.$t('lang.确定'),
                      // callback: () => {
                      //   window.location.reload();
                      // }
                    })
                  }
                  else if(res.code==202){
                    this.loading = false;
                    this.chicang_data = res.data;
                    var arr_cc = [];
                    res.data.map(item=>{
                      arr_cc.push(item.slice(0,-4).toUpperCase())
                    })
                    this.chicang = arr_cc;
                    this.dialogChi = true;
                  }
                  else{
                    this.loading = false;
                    this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
                      confirmButtonText: this.$t('lang.确定'),
                    })
                  }
                })
              }).catch(()=>{
                this.loading = false;
              })
            }
          }
        })
        itemArr.map(item=>{
          this.$alert(item.title+this.$t('lang.数据不能为空'), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
          })
          return false;
        })
      }
    },
    sure_chicang () {//有持仓保存
    
      this.loading = true;
      marketSell(this.order_id,this.chicang_radio, this.chicang_data,this.editableTabs,res=>{
        if(res.code==200){
          this.getUserInfo();
          this.paramefollow();
          this.addapiGet();
          this.loading = false;
          this.dialogChi = false;
          this.$alert(his.$t('lang.保存成功'),this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
            // callback: () => {
            //   window.location.reload();
            // }
          })
        }
        else{
          this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
          })
          this.loading = false;
          this.dialogChi = false;
        }
      })
    },
    sortName(arr,eachName){
      arr.map(item=>{
        let temp=item[eachName];
        item.sortName=temp;
      })
      let resultArray = arr.sort(
          function compareFunction(param1, param2) {
              return param1.sortName.localeCompare(param2.sortName,"en");
          }
      );
      return resultArray;
    },
    getUserInfo () {
      // this.loading = true;
      userInfo (this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
            const btclist = res.btclist;
            const ethlist = res.ethlist;
            const dotlist = res.dotlist;
            const htlist = res.htlist;
            const bchlist = res.bchlist;
            const ltclist = res.ltclist;
            const suntlist = res.suntlist;
            const sollist = res.sollist?res.sollist:[];
            const linklist = res.linklist;
            const bnblist = res.bnblist;
            const okblist = res.okblist;

            //持仓情况
            if(IsNullOrBe(btclist)){
              this.btcnum = Number(btclist.num).toFixed(4);
              this.btcnum_pve = IsNullOrBe(btclist)==false?0.00:Number(btclist.avg).toFixed(2);
              this.btcnum_all = btclist.all;
            }else{
              this.btcnum = "0.0000";
              this.btcnum_pve = "0.00";
              this.btcnum_all ="0.00";
            }
            if(IsNullOrBe(ethlist)){
              this.ethnum = Number(ethlist.num).toFixed(4);
              this.ethnum_pve = IsNullOrBe(ethlist)==false?0.00:Number(ethlist.avg).toFixed(2);
              this.ethnum_all = ethlist.all;
            }else{
              this.ethnum = "0.0000";
              this.ethnum_pve = "0.00";
              this.ethnum_all = "0.00";
            }
            if(IsNullOrBe(dotlist)){
              this.dotnum = Number(dotlist.num).toFixed(4);
              this.dotnum_pve = IsNullOrBe(dotlist)==false?0.00:Number(dotlist.avg).toFixed(2);
              this.dotnum_all = dotlist.all;
            }else{
              this.dotnum = "0.0000";
              this.dotnum_pve = "0.00";
              this.dotnum_all= "0.00";
            }
            if(IsNullOrBe(htlist)){
              this.htnum = Number(htlist.num).toFixed(4);
              this.htnum_pve = IsNullOrBe(htlist)==false?0.00:Number(htlist.avg).toFixed(2);
              this.htnum_all = htlist.all;
            }else{
              this.htnum = "0.0000";
              this.htnum_pve = "0.00";
              this.htnum_all = "0.00";
            }
            if(IsNullOrBe(bchlist)){
              this.bchnum = Number(bchlist.num).toFixed(4);
              this.bchnum_pve = IsNullOrBe(bchlist)==false?0.00:Number(bchlist.avg).toFixed(2);
              this.bchnum_all = bchlist.all;
            }else{
              this.bchnum = "0.0000";
              this.bchnum_pve = "0.00";
              this.bchnum_all = "0.00";
            }
            if(IsNullOrBe(ltclist)){
              this.ltcnum = Number(ltclist.num).toFixed(4);
              this.ltcnum_pve = IsNullOrBe(ltclist)==false?0.00:Number(ltclist.avg).toFixed(2);
              this.ltcnum_all = ltclist.all;
            }else{
              this.ltcnum = "0.00";
              this.ltcnum_pve = "0.00";
              this.ltcnum_all = "0.00";
            }
            if(IsNullOrBe(suntlist)){
              this.suntnum = Number(suntlist.num).toFixed(4);
              this.suntnum_pve = IsNullOrBe(suntlist)==false?0.00:Number(suntlist.avg).toFixed(2);
              this.suntnum_all = suntlist.all;
            }else{
              this.suntnum = "0.0000";
              this.suntnum_pve = "0.00";
              this.suntnum_all = "0.00";
            }
            
            if(IsNullOrBe(sollist)){
              this.solnum = Number(sollist.num).toFixed(4);
              this.solnum_pve = IsNullOrBe(sollist)==false?0.00:Number(sollist.avg).toFixed(2);
              this.solnum_all = sollist.all;
            }else{
              this.solnum = "0.0000";
              this.solnum_pve = "0.00";
              this.solnum_all = "0.00";
            }
            if(IsNullOrBe(linklist)){
              this.linknum = Number(linklist.num).toFixed(4);
              this.linknum_pve = IsNullOrBe(linklist)==false?0.00:Number(linklist.avg).toFixed(2);
              this.linknum_all = linklist.all;
            }else{
              this.linknum = "0.0000";
              this.linknum_pve = "0.00";
              this.linknum_all = "0.00";
            }
            if(IsNullOrBe(bnblist)){
              this.bnbnum = Number(bnblist.num).toFixed(4);
              this.bnbnum_pve = IsNullOrBe(bnblist)==false?0.00:Number(bnblist.avg).toFixed(2);
              this.bnbnum_all = bnblist.all;
            }else{
              this.bnbnum = "0.0000";
              this.bnbnum_pve = "0.00";
              this.bnbnum_all = "0.00";
            }
            if(IsNullOrBe(okblist)){
              this.okbnum = Number(okblist.num).toFixed(4);
              this.okbnum_pve = IsNullOrBe(okblist)==false?0.00:Number(okblist.avg).toFixed(2);
              this.okbnum_all = okblist.all;
            }else{
              this.okbnum = "0.00";
              this.okbnum_pve = "0.00";
              this.okbnum_all = "0.00";
            }


            //工作状态数据、
            var buy_order = res.workingstatus?res.workingstatus.buy:[];
            var sell_order = res.workingstatus?res.workingstatus.sell:[];
            // var work_status = [];
            if(buy_order.length==0&&sell_order.length==0){
              this.noData = true
            }else{
              this.noData = false
              buy_order.map(item=>{
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
              })
              sell_order.map(item=>{
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
              })
              // this.sortName(buy_order,"symbol")
              // work_status.sort(function(x, y){
              //     return y.symbol.slice(0,1) - x.symbol.slice(0,1);
              // });
            }
            // this.work_status = work_status;

            this.sortName(buy_order,"symbol")
            this.sortName(sell_order,"symbol")
            this.buy_order = buy_order;
            this.sell_order = sell_order;
            //历史成交
            var old_buy = res.historystatus?res.historystatus.buy:[];
            var old_sell = res.historystatus?res.historystatus.sell:[];
            var history_list = [];
            if(old_buy.length==0&&old_sell.length==0){
              this.noData1 = true
            }else{
              this.noData1 = false
              old_buy.map(item=>{
                item.order_type=1;
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
                history_list.push(item)
              })
              old_sell.map(item=>{
                item.order_type=2;
                item.symbol = item.symbol.slice(0,-4).toUpperCase();
                history_list.push(item)
              })
              history_list.sort(function(x, y){
                  return y.create_time - x.create_time;
              });
            }
            this.history_list = history_list;
            this.loading = false;
            this.loading1 = false
        }
        else{
          this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
            confirmButtonText: this.$t('lang.确定'),
          })
          this.loading = false;
          this.loading1 = false
          this.noData = true;
          this.noData1 = true;
        }
      })
    },
    userinventory () {
      userinventory(this.order_id,res=>{
        // console.log(res)
        if(res.code==200){
          this.usdtNum = res.currentamount;//当前金额
          this.inventory = res.inventory;//持仓总成本
          this.profitRateNow = res.currentrunning;//当前收益
          this.profitRate = res.currentannual;//预期年化
          this.now_total = res.currentvalue;//当前总值
          this.usdt_deposit = res.usdt_deposit;//充币总额
          this.usdt_withdraw = res.usdt_withdraw;///提币总额
          this.now_profit = res.currentvalue-Number(this.total);
        }
      })
    },
    open() {
      this.dialogMoney = true;
    },
    
    open_time() {
      this.dialogTime = true;
    },
    open_total() {
      this.dialogTotal = true;
    },
    timeChange (time) {
      this.init_time = time;
      this.init_time1 = time?Math.floor(time.getTime()/1000):'';
      // console.log(this.init_time1)
    },
    oninput(value) {
        // 通过正则过滤小数点后两位
      value = (value.match(/^\d*(\.?\d{0,4})/g)[0]) || null;
      
      var status = Number(this.radio);
      if(status==1){
        this.cz_money = value;
      }else if(status==2){
        this.tb_money = value;
      }
    },
    dialogMoneySubmit () {
      // console.log('cz',this.cz_money)
      // console.log('tb',this.tb_money)
      var cz_money = this.cz_money;
      var tb_money = this.tb_money;
      var status = Number(this.radio);
      var usdt_total = status== 1?cz_money:tb_money;
      if(!this.cz_money&&status==1){
        this.$message(this.$t('lang.充值金额不能为空'));
        return false;
      }
      if(!this.tb_money&&status==2){
        this.$message(this.$t('lang.提币金额不能为空'));
        return false;
      }
      this.$confirm(this.$t('lang.确认提交吗？'))
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            depositwithdraw(this.order_id,status,usdt_total,res=>{
              // console.log(res)
              if(res.code==200){
                this.dialogMoney = false;
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'success'
                })
                userinventory(this.order_id,res=>{
                // console.log(res)
                  if(res.code==200){
                    this.usdtNum = res.currentamount;//当前金额
                    this.inventory = res.inventory;//持仓总值
                    this.profitRateNow = res.currentrunning;//当前收益
                    this.profitRate = res.currentannual;//预期年化
                    this.now_total = res.currentvalue;//当前总值
                    this.usdt_deposit = res.usdt_deposit;//充币总额
                    this.usdt_withdraw = res.usdt_withdraw;///提币总额
                    loading.close();
                  }
                })
              }else{
                
                loading.close();
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'error'
                })
              }
            })
          })
          .catch(() => {});
      
    },
    
    dialogTimeSubmit () {
      var init_time = Number(this.init_time);
      var init_time1 = Number(this.init_time1);
      // console.log(init_time)
      if(!init_time){
        this.$message(this.$t('lang.初始时间不能为空'));
        return false;
      }
      this.$confirm(this.$t('lang.确认提交吗？'))
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            changecreatetime(this.order_id,init_time1,res=>{
              // console.log(res)
              if(res.code==200){
                this.dialogTime = false;
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'success'
                })
                loading.close();
                this.addapiGet();
              }else{
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'error'
                })
                loading.close();
              }
            })
          })
          .catch(() => {});
      
    },
    inputChange (value) {
      if(value.length==1){
        value=value.replace(/[^1-9]/g,'')
      }else{
        value=value.replace(/[^A-Za-z0-9]/g,'')
      }
      this.total_input = value;
    },
    dialogTotalSubmit () {
      var total_input = Number(this.total_input);
      if(!total_input){
        this.$message(this.$t('lang.初始金额不能为空'));
        return false;
      }
      this.$confirm(this.$t('lang.确认提交吗？'))
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            changeTotal(this.order_id,total_input,res=>{
              // console.log(res)
              if(res.code==200){
                this.dialogTotal = false;
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'success'
                })
                loading.close();
                this.addapiGet();
              }else{
                this.$message({
                  message: this.$t('lang.' + res.msg),
                  type: 'error'
                })
                loading.close();
              }
            })
          })
          .catch(() => {});
      
    },
  },
  mounted () {
    if(localStorage.loginStyle==2){
      this.order_id = localStorage.orderId;
      if(localStorage.orderId==""||localStorage.orderId==null||localStorage.orderId==undefined){     
        this.$router.push('/');
      }else{
        // if(dot == 0){
        //   loginOrderId(localStorage.orderId,res=>{
        //     if(res.code == 200){
        //       this.getUserInfo ();
        //       this.userinventory ();
        //       this.paramefollow ();
        //       this.addapiGet ();
        //       // this.parmeworking();//系统推荐参数
        //       dot++;
        //     }else{
        //       this.$message({
        //         message: '登录信息已失效，请重新登录',
        //         onClose: () => {
        //           this.$router.push('/Login');
        //           localStorage.orderId = "";
        //         }
        //       });
        //     }
        //   })
        // }else{
          this.getUserInfo ();
          this.userinventory ();
          this.paramefollow ();
          this.addapiGet ();
          // this.parmeworking();//系统推荐参数
        // }
      }
    }
    else if(localStorage.loginStyle==1){
      this.mobile = localStorage.mobile;
      this.tacticsmessage()
    }
    else{
      this.$router.push('/');
      localStorage.mobile = '';
      localStorage.orderId = '';
      localStorage.loginStyle = '';
    }
  },
  created () {
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.robot_set{
  .tishi{
    font-size: @font10;
    line-height: 24px;
    color: @grey[0];
  }
  .el-dialog{
    .el-input{
      width: 100%;
    }
  }
  .details_box{
    width: 100%;
    min-height: calc(100% - 286px);
    overflow: hidden;
    padding-bottom: 80px;
    margin: auto;
    .el-button--default{
      color: @grey[0];
    }
    .el-button--default:hover{
      border: 1px solid #DCDFE6;
      color: @grey[0];
      background-color: transparent;
    }
    .main_container{
      min-width: @minWidth;
      max-width: @maxWidth;
      margin: auto;
    }
    .sys_set{
      display: flex;
      padding-top: 40px;
        
      .desc_herf{
        color: @herfColor;
        text-decoration: underline;
        cursor: pointer;
        padding-left: 12px;
        font-size: 12px;
        line-height: 17px;
      }
      .desc_herf1{
        font-size: 16px;
        line-height: 24px;
      }
      .el-row{
        margin-bottom: 10px;
        line-height: 24px;
        display: flex;
        align-items: center;
      }
      .grid-content{
        font-size: @font14;
        .api_change_style{
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid rgba(0,0,0,0.15);
          .style_btn{
            width: 100%;
            border: 1px solid @bgColor1;
            color: @bgColor1;
            margin-right: 30px;
            background-color: transparent;
            border-radius: 12px;
            border-radius: 4px;
            text-align: center;
            padding: 6px 10px;
            cursor: pointer;
          }
          .style_btn1{
            width: 100%;
            border: 1px solid #E4E7ED;
            color: #C0C4CC;
            margin-right: 30px;
            background-color: #F5F7FA;
            border-radius: 12px;
            border-radius: 4px;
            text-align: center;
            padding: 6px 10px;
            cursor: pointer;
          }
          .style_btn.on{
            border: 1px solid @bgColor1;
            color: #fff;
            background-color: @bgColor1;
          }
        }
        .el-form-item{
          margin-bottom: 12px;
        }
        .el-form-item__label{
          text-align: left;
          line-height: 28px;
        }
        .el-input,.el-select{
          width: 100%;
        }
        .zhu{
          margin-bottom: 12px;
        }
        .text{
          color: rgba(16,16,16,0.8);
          font-weight: bold;
          line-height: 17px;
          font-size: 12px;
        }
        .desc{
          color: @grey[0];
          font-size: 16px;
        }
        .green{
          color: @green;
        }
        .red{
          color: @red;
        }
        .change_api{
          padding: 6px 20px;
          background: @bgColor1;
          border-color: @bgColor1;
        }
      }
      .set_l{
        display: flex;
        flex-direction: column;
        flex: 7;
        margin-right: 32px;
        padding-right: 32px;
        border-right: 1px solid rgba(0,0,0,0.2);
        .tit{
          display: flex;
          justify-content: space-between;
          font-size: @font20;
          line-height: @font32;
          color: @bgColor1;
          margin-bottom: 20px;
          font-weight: bold;
        }
        .sys_switch_con{
          display: flex;
          align-items: center;
          .sys_switch{
            font-size: @font20;
            color: rgba(0,0,0,0.6);
            margin-right: 18px;
          }
          .el-switch{
            height: 32px;
            line-height: 32px;
            margin-top: -1px;
          }
          .el-switch__core {
            width: 60px !important;
            height: 30px;
            border-radius: 20px;
          }
          .el-switch__core::after {
            width: 26px;
            height: 26px;
          }
          
          .el-switch.is-checked .el-switch__core::after{
            margin-left: -28px;
          }
        }
        .set_btn{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 14px 0 22px 0;
          .el-button{
            font-size: @font16;
          }
          .el-button:hover{
            border: 1px solid @bgColor1;
            color: @bgColor1;
          }
          .el-button:focus{
            border: 1px solid #DCDFE6;
            color: @grey[0];
            background-color: transparent;
          }
          .el-button.active{
            border: 1px solid @bgColor1;
            color: @colorWhite;
            background-color: @bgColor1;
          }
          .reset:hover{
            border: 1px solid @btnColor;
            color: @btnColor;
          }
          .reset:focus{
            border: 1px solid #DCDFE6;
            color: @grey[0];
            background-color: transparent;
          }
          .save,.save:hover{
            border: 1px solid @bgColor1;
            color: @colorWhite;
            background-color: @bgColor1;
          }
        }
        .coin_checkbox{
          .el-checkbox{
            width: 20%;
            margin-right: 0;
            margin-bottom: 14px;
            .el-checkbox__inner{
              width: 16px;
              height: 16px;
            }
            .el-checkbox__input.is-checked+.el-checkbox__label{
              color: rgba(16,16,16,0.8);
              font-weight: @fontWeight[600];
            }
            .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
              background-color: @herfColor;
              border-color: @herfColor;
            }
            .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
              border-color: #fff;
            }
            .el-checkbox__inner::after{
              left: 5px;
            }
          }
        }
        .set_btn1{
          .el-button{
            width: 100%;
          }
        }
        
        .save_strategy,.save_strategy:hover.save_strategy:focus{
          background: @bgColor1!important;
          color: #fff!important;
          border: 1px solid @bgColor1!important;
        }
        .set_con{
          display: flex;
          justify-content: space-between;
          position: relative;
          .el-tabs__nav-wrap::after{
            display: none;
          }
          .set_status{
            position: absolute;
            right: 0;
            top: 10px;
            text-decoration: underline;
            color: @herfColor;
            cursor: pointer;
          }
          .set_status1{
            color: @grey[0];
            text-decoration: none;
            font-size: @font10;
          }
          .el-tabs{
            width: 100%;
          }
          .el-tabs__header{
            margin: 0 0 14px;
          }
          .el-tabs__item{
            font-size: @font16;
            color: @grey[2];
            padding: 0;
            padding-right: 14px;
          }
          .el-tabs__active-bar{
            background-color: @bgColor1;
          }
          .el-tabs__item.is-active{
            color: @bgColor1;
            font-weight: @fontWeight[600];
          }
          .el-tabs__item:hover{
            color: @bgColor1;
          }
          .set_grid{
            .title{
              color: rgba(16,16,16,0.8);
              font-size: 12px;
              font-weight: bold;
            }
            .desc{
              display: flex;
              flex: 1;
            }
            .el-input__inner{
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }
      .set_r{
        display: flex;
        flex-direction: column;
        flex: 4;
        .set_r_header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .title{
            font-size: @font20;
            line-height: @font32;
            color: @bgColor1;
            font-weight: bold;
          }
          .reset{
            padding: 8px 20px;
            font-size: 12px;
            color: @colorWhite;
            background: @bgColor1;
            border: 1px solid @bgColor1;
          }
        }
        .card_item{
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;
          .item_flex{
            display: flex;
            flex-direction: column;
            .desc{
              color: rgba(16,16,16,0.5);
              font-size: @font16;
              line-height: 20px;
            }
            .text{
              color: rgba(16,16,16,0.8);
              font-size: @font12;
              line-height: 17px;
              font-weight: bold;
            }
          }
        }
        .set_con{
          display: flex;
          justify-content: space-between;
          position: relative;
          margin-top: 10px;
          .el-tabs__nav-wrap::after{
            display: none;
          }
          .set_status{
            position: absolute;
            right: 0;
            top: 10px;
            text-decoration: underline;
            color: @herfColor;
            cursor: pointer;
          }
          .el-tabs{
            width: 100%;
          }
          .el-tabs__header{
            margin: 0 15px 20px 0;
          }
          .el-tabs__item{
            font-size: @font20;
            color: @grey[2];
          }
          .el-tabs__active-bar{
            background-color: @bgColor1;
          }
          .el-tabs__item.is-active{
            color: @bgColor1;
            font-weight: @fontWeight[600];
          }
          .el-tabs__item:hover{
            color: @bgColor1;
          }
          .tit_table{
            // padding-right: 15px;
            color: rgba(16,16,16,0.8);
            .el-col:last-child{
              .tit_item{
                text-align: right;
              }
            }
          }
          .body_item{
            margin-bottom: 8px;
            line-height: 24px;
            color: rgba(16,16,16,0.5);
            align-items: flex-start;
            .item_num{
              font-size: 14px;
            }
            .el-col:last-child{
              .item_num{
                text-align: right;
              }
            }
          }
          .body_item:last-child{
            margin-bottom: 0;
          }
          .body_item.on{
            font-weight: bold;
            color: rgba(16,16,16,0.8);
          }
          .history_data{
            height: 320px;
            overflow-y: auto;
            margin-right: -8px;
          }
          .no_data{
            font-size: @font14;
            text-align: center;
            margin-top: 40px;
            color: @grey[0];
          }
        }
      }
      .zhu_set{
        color: rgba(0,0,0,0.6);
        margin-top: 10px;
      }
      .ct_div{
        margin-bottom: 10px;
      }
    }
  }
}
</style>
